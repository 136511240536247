export const WORDS: any = [
  {
    word: "aaron",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/7?lang=eng",
    verse: "Exodus 7:1",
    verseText: "And the Lord said unto Moses, See, I have made thee a god to Pharaoh: and AARON thy brother shall be thy prophet.",
  }, // March 30,
  {
    word: "blood",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/7.17?lang=eng",
    verse: "Exodus 7:17",
    verseText: "Thus saith the Lord, In this thou shalt know that I am the Lord: behold, I will smite with the rod that is in mine hand upon the waters which are in the river, and they shall be turned to BLOOD."
  }, // March 31,
  {
    word: "token",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/12.13?lang=eng",
    verse: "Exodus 12:13",
    verseText: "And the blood shall be to you for a TOKEN upon the houses where ye are: and when I see the blood, I will pass over you, and the plague shall not be upon you to destroy you, when I smite the land of Egypt."
  }, // April 1,
  {
    word: "serve",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/9.1?lang=eng",
    verse: "Exodus 9.1",
    verseText: "Then the Lord said unto Moses, Go in unto Pharaoh, and tell him, Thus saith the Lord God of the Hebrews, Let my people go, that they may SERVE me."
  }, // April 2,
  {
    word: "cloud",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/13.21?lang=eng",
    verse: "Exodus 13:21",
    verseText: "And the Lord went before them by day in a pillar of a CLOUD, to lead them the way; and by night in a pillar of fire, to give them light; to go by day and night."
  }, // April 3,
  {
    word: "fight",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/14.14?lang=eng",
    verse: "Exodus 14:14",
    verseText: "The Lord shall FIGHT for you, and ye shall hold your peace."
  }, // April 4,
  {
    word: "exalt",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/15.2?lang=eng",
    verse: "Exodus 15:2",
    verseText: "The Lord is my strength and song, and he is become my salvation: he is my God, and I will prepare him an habitation; my father’s God, and I will EXALT him."
  }, // April 5,
  {
    word: "reign",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/15.18?lang=eng",
    verse: "Exodus 15:18",
    verseText: "The Lord shall REIGN for ever and ever."
  }, // April 6,
  {
    word: "glory",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/16.7?lang=eng",
    verse: "Exodus 16:7",
    verseText: "And in the morning, then ye shall see the GLORY of the Lord; for that he heareth your murmurings against the Lord: and what are we, that ye murmur against us?"
  }, // April 7,
  {
    word: "manna",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/16.35?lang=eng",
    verse: "Exodus 16:35",
    verseText: "And the children of Israel did eat MANNA forty years, until they came to a land inhabited; they did eat manna, until they came unto the borders of the land of Canaan."
  }, // April 8,
  {
    word: "heavy",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/17.12?lang=eng",
    verse: "Exodus 17:12",
    verseText: "But Moses’ hands were heavy; and they took a stone, and put it under him, and he sat thereon; and Aaron and Hur stayed up his hands, the one on the one side, and the other on the other side; and his hands were steady until the going down of the sun."
  }, // April 9,
  {
    word: "saved",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/14.30?lang=eng",
    verse: "Exodus 14:30",
    verseText: "Thus the Lord SAVED Israel that day out of the hand of the Egyptians; and Israel saw the Egyptians dead upon the sea shore."
  }, // April 10
  {
    word: "alive",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/15.22?lang=eng",
    verse: "1 Corinthians 15:22",
    verseText: "For as in Adam all die, even so in Christ shall all be made ALIVE.",
    hint: "1 Corinthians 15",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/nt/1-cor/15",
  }, // April 11
  {
    word: "peace",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/nt/john/14.27",
    verse: "John 14:27",
    verseText: "PEACE I leave with you, my peace I give unto you: not as the world giveth, give I unto you. Let not your heart be troubled, neither let it be afraid.",
    hint: "John 14",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/nt/john/14",
    submissionText: "Thank you Hannah Wilcox for submitting this word!"
  }, // April 12
  {
    word: "mercy",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/7.12",
    verse: "Alma 7:12",
    verseText: "And he will take upon him death, that he may loose the bands of death which bind his people; and he will take upon him their infirmities, that his bowels may be filled with MERCY, according to the flesh, that he may know according to the flesh how to succor his people according to their infirmities.",
    hint: "Alma 7",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/7",
    submissionText: ""
   }, // April 13
  {
    word: "world",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/the-living-christ-the-testimony-of-the-apostles/the-living-christ-the-testimony-of-the-apostles?lang=eng&id=5#p5",
    verse: "The Living Christ",
    verseText: "We solemnly testify that His life, which is central to all human history, neither began in Bethlehem nor concluded on Calvary. He was the Firstborn of the Father, the Only Begotten Son in the flesh, the Redeemer of the WORLD.",
    hint: "The Living Christ",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/the-living-christ-the-testimony-of-the-apostles/the-living-christ-the-testimony-of-the-apostles",
    submissionText: ""
  }, // April 14
   {
    word: "lives",
    verseUrl: "https://www.youtube.com/watch?v=8KCUs7oJxjc",
    verse: "The Good News Video",
    verseText: "He lives!",
    hint: "this video",
    hintUrl: "https://www.youtube.com/watch?v=8KCUs7oJxjc",
    submissionText: ""
  }, // April 15
  {
    word: "hands",
    verseUrl: "https://www.churchofjesuschrist.org/study/manual/hymns/i-stand-all-amazed",
    verse: "I Stand All Amazed",
    verseText: "I think of his hands pierced and bleeding to pay the debt! Such mercy, such love and devotion can I forget?",
    hint: "I Stand All Amazed",
    hintUrl: "https://www.churchofjesuschrist.org/study/manual/hymns/i-stand-all-amazed",
    submissionText: ""
  }, // April 16
  {
    word: "risen",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/24.6",
    verse: "Luke 24:6",
    verseText: "He is not here, but is risen: remember how he spake unto you when he was yet in Galilee",
    hint: "Luke 24",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/nt/luke/24",
    submissionText: ""
  }, // April 17
  {
    word: "alien",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/18.3",
    verse: "Exodus 18:3",
    verseText: "And her two sons; of which the name of the one was Gershom; for he said, I have been an alien in a strange land:",
    hint: "Exodus 18",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/18",
    submissionText: "Thanks to Jenn Hall and Austin Smith for submitting this word!"
  }, // April 18
  {
    word: "sinai",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/19.11",
    verse: "Exodus 19:11",
    verseText: "And be ready against the third day: for the third day the Lord will come down in the sight of all the people upon mount Sinai.",
    hint: "Exodus 19",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/19",
    submissionText: ""
  }, // April 19
  {
    word: "voice",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/19.5",
    verse: "Exodus 19:5",
    verseText: "Now therefore, if ye will obey my voice indeed, and keep my covenant, then ye shall be a peculiar treasure unto me above all people: for all the earth is mine:",
    hint: "Exodus 19",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/19",
    submissionText: ""
  }, // April 20
  {
    word: "jethro",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/18.10",
    verse: "Exodus 18:10",
    verseText: "And Jethro said, Blessed be the Lord, who hath delivered you out of the hand of the Egyptians, and out of the hand of Pharaoh, who hath delivered the people from under the hand of the Egyptians.",
    hint: "Exodus 18",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/18",
    submissionText: ""
  }, // April 21
  {
    word: "alone",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/18.18",
    verse: "Exodus 18:18",
    verseText: "Thou wilt surely wear away, both thou, and this people that is with thee: for this thing is too heavy for thee; thou art not able to perform it thyself alone.",
    hint: "Exodus 18",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/18",
    submissionText: ""
  }, // April 22
  {
    word: "prove",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/20.20",
    verse: "Exodus 20:20",
    verseText: "And Moses said unto the people, Fear not: for God is come to prove you, and that his fear may be before your faces, that ye sin not.",
    hint: "Exodus 20",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/20",
    submissionText: ""
  }, // April 23
  {
    word: "altar",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/20.24",
    verse: "Exodus 20:24",
    verseText: "An altar of earth thou shalt make unto me, and shalt sacrifice thereon thy burnt offerings, and thy peace offerings, thy sheep, and thine oxen: in all places where I record my name I will come unto thee, and I will bless thee.",
    hint: "Exodus 20",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/20",
    submissionText: "Thanks to Crystal Wray for submitting this word!"
  }, // April 24
  {
    word: "stone",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/24.12",
    verse: "Exodus 24:12",
    verseText: "And the Lord said unto Moses, Come up to me into the mount, and be there: and I will give thee tables of stone, and a law, and commandments which I have written; that thou mayest teach them.",
    hint: "Exodus 24",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/24",
    submissionText: "Thanks to Drew Vance for submitting this word!"
  }, // April 25
  {
    word: "forty",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/24.18",
    verse: "Exodus 24:18",
    verseText: "And Moses went into the midst of the cloud, and gat him up into the mount: and Moses was in the mount forty days and forty nights.",
    hint: "Exodus 24",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/24",
    submissionText: "Thanks to Sloan Guisinger for submitting this word!"
  }, // April 26
  {
    word: "anger",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/32.19",
    verse: "Exodus 32:19",
    verseText: "And it came to pass, as soon as he came nigh unto the camp, that he saw the calf, and the dancing: and Moses’ anger waxed hot, and he cast the tables out of his hands, and brake them beneath the mount.",
    hint: "Exodus 32",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/32",
    submissionText: "Thanks to Chatti Stavast for submitting this word!"
  }, // April 27
  {
    word: "lists",
    verseUrl: "https://www.churchofjesuschrist.org/study/general-conference/2015/04/the-sabbath-is-a-delight?lang=eng&para=11",
    verse: "The Sabbath is a Delight",
    verseText: "It wasn’t until later that I learned from the scriptures that my conduct and my attitude on the Sabbath constituted a sign between me and my Heavenly Father. With that understanding, I no longer needed lists of dos and don’ts... I simply asked myself, “What sign do I want to give to God?",
    hint: "this conference talk",
    hintUrl: "https://www.churchofjesuschrist.org/study/general-conference/2015/04/the-sabbath-is-a-delight?lang=eng",
    submissionText: ""
  }, // April 28
  {
    word: "spake",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/33.11",
    verse: "Exodus 33:11",
    verseText: "And the Lord spake unto Moses face to face, as a man speaketh unto his friend. And he turned again into the camp: but his servant Joshua, the son of Nun, a young man, departed not out of the tabernacle.",
    hint: "Exodus 33",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/33",
    submissionText: "Thanks to Maxwell Stewart for submitting this word!"
  }, // April 29
  {
    word: "truth",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/34.6",
    verse: "Exodus 34:6",
    verseText: "And the Lord passed by before him, and proclaimed, The Lord, The Lord God, merciful and gracious, longsuffering, and abundant in goodness and truth,",
    hint: "Exodus 34",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/34",
    submissionText: "Thanks to Jillian Capri Glazier for submitting this word!"
  }, // April 30
  {
    word: "guide",
    verseUrl: "https://www.churchofjesuschrist.org/study/manual/hymns/abide-with-me",
    verse: "Abide With Me",
    verseText: "I need thy presence ev’ry passing hour. What but thy grace can foil the tempter’s pow’r? Who, like thyself, my guide and stay can be? Thru cloud and sunshine, Lord, abide with me!",
    hint: "this hymn",
    hintUrl: "https://www.churchofjesuschrist.org/study/manual/hymns/abide-with-me",
    submissionText: ""
  }, // May 1
  {
    word: "ephod",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/35.9",
    verse: "Exodus 35:9",
    verseText: "And onyx stones, and stones to be set for the ephod, and for the breastplate.",
    hint: "Exodus 35",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/35",
    submissionText: "Thanks to David Foutz for submitting this word!"
  }, // May 2
  {
    word: "bring",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/36.5",
    verse: "Exodus 36:5",
    verseText: "And they spake unto Moses, saying, The people abring much more than enough for the service of the work, which the Lord commanded to make.",
    hint: "Exodus 36",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/ex/36",
    submissionText: ""
  }, // May 3
  {
    word: "faith",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/25.16",
    verse: "Alma 25:16",
    verseText: "Now they did not suppose that salvation came by the law of Moses; but the law of Moses did serve to strengthen their faith in Christ; and thus they did retain a hope through faith, unto eternal salvation, relying upon the spirit of prophecy, which spake of those things to come.",
    hint: "Alma 25",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/25",
    submissionText: ""
  }, // May 4
  {
    word: "clean",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/lev/16.30",
    verse: "Leviticus 16:30",
    verseText: "For on that day shall the priest make an atonement for you, to cleanse you, that ye may be clean from all your sins before the Lord.",
    hint: "Leviticus 16",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/lev/16",
    submissionText: ""
  }, // May 5
  {
    word: "place",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/60.7",
    verse: "D&C 60:7",
    verseText: "And in this place let them lift up their voice and declare my word with loud voices, without wrath or doubting, lifting up holy hands upon them. For I am able to make you holy, and your sins are forgiven you.",
    hint: "D&C 60",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/60",
    submissionText: ""
  }, // May 6
  {
    word: "burnt",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/lev/1.3",
    verse: "Leviticus 1:3",
    verseText: "If his offering be a burnt sacrifice of the herd, let him offer a male without blemish: he shall offer it of his own voluntary will at the door of the tabernacle of the congregation before the Lord.",
    hint: "Leviticus 1",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/lev/1?",
    submissionText: "Thanks to Abby Lefler for submitting this word!"
  }, // May 7  
  {
    word: "idols",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/lev/19.4",
    verse: "Leviticus 19:4",
    verseText: "Turn ye not unto idols, nor make to yourselves molten gods: I am the Lord your God.",
    hint: "Leviticus 19",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/lev/19",
    submissionText: ""
  }, // May 8
  {
    word: "stand",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/num/11.16",
    verse: "Numbers 11:16",
    verseText: "And the Lord said unto Moses, Gather unto me seventy men of the elders of Israel, whom thou knowest to be the elders of the people, and officers over them; and bring them unto the tabernacle of the congregation, that they may stand there with thee.",
    hint: "Numbers 11",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/num/11",
    submissionText: ""
  }, // May 9
  {
    word: "mouth",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/num/12.8",
    verse: "Numbers 12:8",
    verseText: "With him will I speak mouth to mouth, even apparently, and not in dark speeches; and the similitude of the Lord shall he behold: wherefore then were ye not afraid to speak against my servant Moses?",
    hint: "Numbers 12",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/num/12",
    submissionText: "Thanks to Elaine Pladgeman for submitting this word!"
  }, // May 10
  {
    word: "sight",
    hint: "Numbers 13",
    verse: "Numbers 13:33",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/num/13",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/num/13.33",
    verseText: "And there we saw the giants, the sons of Anak, which come of the giants: and we were in our own sight as grasshoppers, and so we were in their sight.",
    submissionText: ""
  }, // May 11
  {
    word: "smote",
    hint: "Numbers 20",
    verse: "Numbers 20:11",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/num/20",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/num/20.11",
    verseText: "And Moses lifted up his hand, and with his rod he smote the rock twice: and the water came out abundantly, and the congregation drank, and their beasts also.",
    submissionText: ""
  }, // May 12
  {
    word: "fiery",
    hint: "Numbers 21",
    verse: "Numbers 21:8",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/num/21",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/num/21.8",
    verseText: "And the Lord said unto Moses, Make thee a fiery serpent, and set it upon a pole: and it shall come to pass, that every one that is bitten, when he looketh upon it, shall live.",
    submissionText: ""
  }, // May 13
  {
    word: "clear",
    hint: "this talk",
    verse: "Revelation for the Church, Revelation for Our Lives",
    hintUrl: "https://www.churchofjesuschrist.org/study/general-conference/2018/04/revelation-for-the-church-revelation-for-our-lives",
    verseUrl: "https://www.churchofjesuschrist.org/study/general-conference/2018/04/revelation-for-the-church-revelation-for-our-lives.33",
    verseText: "Oh, there is so much more that your Father in Heaven wants you to know. As Elder Neal A. Maxwell taught, To those who have eyes to see and ears to hear, it is clear that the Father and the Son are giving away the secrets of the universe!",
    submissionText: ""
  }, // May 14
  {
    word: "moses",
    hint: "John 3",
    verse: "John 3:14",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/nt/john/3",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/nt/john/3.14",
    verseText: "And as Moses lifted up the serpent in the wilderness, even so must the Son of man be lifted up",
    submissionText: ""
  }, // May 15
  {
    word: "honey",
    hint: "Deuteronomy 6",
    verse: "Deuteronomy 6:3",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/deut/6",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/deut/6.3",
    verseText: "Hear therefore, O Israel, and observe to do it; that it may be well with thee, and that ye may increase mightily, as the Lord God of thy fathers hath promised thee, in the land that floweth with milk and HONEY.",
    submissionText: "Thanks to Kenna Rushforth for submitting this word!"
  }, // May 16
  {
    word: "earth",
    hint: "Deuteronomy 7",
    verse: "Deuteronomy 7:6",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/deut/7",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/deut/7.6",
    verseText: "For thou art an holy people unto the Lord thy God: the Lord thy God hath chosen thee to be a special people unto himself, above all people that are upon the face of the EARTH.",
    submissionText: ""
  }, // May 17
  {
    word: "every",
    hint: "Deuteronomy 8",
    verse: "Deuteronomy 8:3",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/deut/8",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/deut/8.3",
    verseText: "And he humbled thee, and suffered thee to hunger, and fed thee with manna, which thou knewest not, neither did thy fathers know; that he might make thee know that man doth not live by bread only, but by EVERY word that proceedeth out of the mouth of the Lord doth man live.",
    submissionText: ""
  }, // May 18
  {
    word: "works",
    hint: "Deuteronomy 15",
    verse: "Deuteronomy 15:10",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/deut/15",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/deut/15.10",
    verseText: "Thou shalt surely give him, and thine heart shall not be grieved when thou givest unto him: because that for this thing the Lord thy God shall bless thee in all thy WORKS, and in all that thou puttest thine hand unto.",
    submissionText: "Thanks to Chatti Stavast for submitting this word!"
  }, // May 19
  {
    word: "raise",
    hint: "Deuteronomy 18",
    verse: "Deuteronomy 18:18",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/deut/18",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/deut/18.18",
    verseText: "I will RAISE them up a Prophet from among their brethren, like unto thee, and will put my words in his mouth; and he shall speak unto them all that I shall command him.",
    submissionText: ""
  }, // May 20
  {
    word: "truly",
    hint: "Acts 3",
    verse: "Acts 3:22",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/3",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/nt/acts/3.22",
    verseText: "For Moses TRULY said unto the fathers, A prophet shall the Lord your God raise up unto you of your brethren, like unto me; him shall ye hear in all things whatsoever he shall say unto you.",
    submissionText: ""
  }, // May 21
  {
    word: "sware",
    hint: "Deuteronomy 34",
    verse: "Deuteronomy 34:4",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/deut/34",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/deut/34.4",
    verseText: "And the Lord said unto him, This is the land which I SWARE unto Abraham, unto Isaac, and unto Jacob, saying, I will give it unto thy seed: I have caused thee to see it with thine eyes, but thou shalt not go over thither.",
    submissionText: "Thanks to Hyrum Shamo for submitting this word!"
  }, // May 22
  {
    word: "goest",
    hint: "Joshua 1",
    verse: "Joshua 1:9",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/1",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/1.9",
    verseText: "Have not I commanded thee? Be strong and of a good courage; be not afraid, neither be thou dismayed: for the Lord thy God is with thee whithersoever thou GOEST.",
    submissionText: "Thanks to Crystal Taylor for submitting this word!"
  }, // May 23
  {
    word: "among",
    hint: "Joshua 3",
    verse: "Joshua 3:5",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/3",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/3.5",
    verseText: "And Joshua said unto the people, Sanctify yourselves: for to morrow the Lord will do wonders AMONG you.",
    submissionText: "Thanks to Colette Oldham for submitting this word!"
  }, // May 24
  {
    word: "loose",
    hint: "Joshua 5",
    verse: "Joshua 5:15",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/5",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/5.15",
    verseText: "And the captain of the Lord’s host said unto Joshua, LOOSE thy shoe from off thy foot; for the place whereon thou standest is holy. And Joshua did so.",
    submissionText: ""
  }, // May 25
  {
    word: "blast",
    hint: "Joshua 6",
    verse: "Joshua 6:5",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/6",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/6.5",
    verseText: "And it shall come to pass, that when they make a long BLAST with the ram’s horn, and when ye hear the sound of the trumpet, all the people shall shout with a great shout; and the wall of the city shall fall down flat, and the people shall ascend up every man straight before him.",
    submissionText: "Thanks to Lara Goeckeritz for submitting this word!"
  }, // May 26
  {
    word: "seize",
    hint: "Joshua 8",
    verse: "Joshua 8:7",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/8",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/8.7",
    verseText: "Then ye shall rise up from the ambush, and SEIZE upon the city: for the Lord your God will deliver it into your hand.",
    submissionText: "Thanks to Chatti Stavast for submitting this word!"
  }, // May 27
  {
    word: "expel",
    hint: "Joshua 23",
    verse: "Joshua 23:5",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/23",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/23.5",
    verseText: "And the Lord your God, he EXPEL expel them from before you, and drive them from out of your sight; and ye shall possess their land, as the Lord your God hath promised unto you.",
    submissionText: ""
  }, // May 28
  {
    word: "house",
    hint: "Joshua 24",
    verse: "Joshua 24:15",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/24",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/josh/24.15",
    verseText: "And if it seem evil unto you to serve the Lord, choose you this day whom ye will serve; whether the gods which your fathers served that were on the other side of the flood, or the gods of the Amorites, in whose land ye dwell: but as for me and my HOUSE, we will serve the Lord.",
    submissionText: "Thanks to Kayla Larsen for submitting this word!"
  }, // May 29
  {
    word: "arose",
    hint: "Judges 2",
    verse: "Judges 2:10",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/2",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/2.10",
    verseText: "And also all that generation were gathered unto their fathers: and there AROSE another generation after them, which knew not the Lord, nor yet the works which he had done for Israel.",
    submissionText: ""
  }, // May 30
  {
    word: "night",
    hint: "Judges 6",
    verse: "Judges 6:40",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/6",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/6.40",
    verseText: "And God did so that NIGHT: for it was dry upon the fleece only, and there was dew on all the ground.",
    submissionText: ""
  }, // May 31
  {
    word: "sword",
    hint: "Judges 7",
    verse: "Judges 7:18",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/7",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/7.18",
    verseText: "When I blow with a trumpet, I and all that are with me, then blow ye the trumpets also on every side of all the camp, and say, The SWORD of the Lord, and of Gideon.",
    submissionText: ""
  }, // June 1
  {
    word: "child",
    hint: "Judges 13",
    verse: "Judges 13:24",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/13",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/13.24",
    verseText: "And the woman bare a son, and called his name Samson: and the CHILD grew, and the Lord blessed him.",
    submissionText: ""
  }, // June 2
  {
    word: "heaps",
    hint: "Judges 15",
    verse: "Judges 15:16",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/15",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/15.16",
    verseText: "And Samson said, With the jawbone of an ass, HEAPS upon HEAPS, with the jaw of an ass have I slain a thousand men.",
    submissionText: ""
  }, // June 3
  {
    word: "razor",
    hint: "Judges 16",
    verse: "Judges 16:17",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/16",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/16.17",
    verseText: "That he told her all his heart, and said unto her, There hath not come a RAZOR upon mine head; for I have been a Nazarite unto God from my mother’s womb: if I be shaven, then my strength will go from me, and I shall become weak, and be like any other man.",
    submissionText: "Thanks to Chelise Fredericks for submitting this word!"
  }, // June 4
  {
    word: "bowed",
    hint: "Judges 16",
    verse: "Judges 16:30",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/16",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/judg/16.30",
    verseText: "And Samson said, Let me die with the Philistines. And he BOWED himself with all his might; and the house fell upon the lords, and upon all the people that were therein. So the dead which he slew at his death were more than they which he slew in his life.",
    submissionText: ""
  }, // June 5
  {
    word: "",
    hint: "",
    verse: "",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseText: "",
    submissionText: ""
  }, // June 6
  {
    word: "",
    hint: "",
    verse: "",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseText: "",
    submissionText: ""
  }, // June 7
  {
    word: "",
    hint: "",
    verse: "",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseText: "",
    submissionText: ""
  }, // June 8
  {
    word: "",
    hint: "",
    verse: "",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseText: "",
    submissionText: ""
  }, // June 9
  {
    word: "",
    hint: "",
    verse: "",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseText: "",
    submissionText: ""
  }, // June 10
  {
    word: "",
    hint: "",
    verse: "",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseText: "",
    submissionText: ""
  }, // June 11
  {
    word: "",
    hint: "",
    verse: "",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseText: "",
    submissionText: ""
  }, // June 12
  {
    word: "",
    hint: "",
    verse: "",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseText: "",
    submissionText: ""
  }, // June 13
  {
    word: "",
    hint: "",
    verse: "",
    hintUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseUrl: "https://www.churchofjesuschrist.org/study/scriptures/ot/",
    verseText: "",
    submissionText: ""
  }, // June 14
];
